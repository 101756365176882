import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlassCheers } from "@fortawesome/pro-light-svg-icons"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="👰🤵 Thanks" />
        <div className="thanks text-secondary text-xl pt-8 pb-4 lg:py-20">
          <div className="container-fluid flex flex-wrap ">
            <div className="w-full md:w-6/12 md:pr-5 md:pl-3 order-1 md:order-12">
              <h1 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl font-display mb-2 sm:mb-0">
                Cheers{" "}
                <FontAwesomeIcon
                  className="font-thin ml-1"
                  icon={faGlassCheers}
                />
              </h1>
              <p className="text-xl">Thank you for letting us know.</p>
              <p className="text-xl">
                Please check back closer to the time for more information.
              </p>
              <p className="text-xl">
                If you need to get in touch then you can{" "}
                <a
                  href="mailto:hollyelliott@hotmail.com"
                  title="Email us"
                  className="underline hover:underline"
                >
                  email us
                </a>
                .
              </p>
              <p className="text-xl">We can't wait to see you. x</p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default IndexPage
